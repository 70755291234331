.delete-button-position {
  position: absolute;
  top: 35px;
  right: 170px;
}

.no-result-align {
  text-align: center;
  color: red;
}

.activity-list-border {
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
}

.rep-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.each-detail.reps input[type="number"] {
  margin-right: 5px;
}

.each-detail-duration {
  margin-bottom: 0.5rem;
}

.leaderboard-scroll {
  height: 85%;
  overflow-y: hidden;
}

.leaderboard-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000000;
}

.leaderboard-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.leaderboard-scroll::-webkit-scrollbar {
  width: 12px;
}

.remaining-time-counter{
  font-size: 1.5rem;
    font-weight: 600;
    color: rgb(85, 88, 92);
}

.disable-radio-btns{
  cursor: auto;
}