.dashboard table td {
  padding: 10px;
}
.dashboard table th {
  font-style: italic;
  border-bottom: 1px solid #000;
  border-top: 0;
  font-weight: normal;
  font-size: 14px;
}
.dashboard table th:last-child,
.dashboard table td:last-child {
  text-align: right;
}
.dashboard table th:first-child,
.dashboard table td:first-child {
  width: 78px;
}
.table td,
.table th {
  vertical-align: middle;
}
.dashboard table td:last-child {
  font-size: 22px;
  font-weight: bold;
}
.left-list {
  margin-right: 40px;
  color: #0d1428;
  font-size: 18px;
}
.left-list li {
  margin: 15px 0;
  font-size: 18px;
}
.left-list span {
  color: red;
  font-size: 16px;
}
.right-list {
  background: #f7f7f7;
  padding: 20px 50px;
  font-size: 18px;
  border: solid 1px #ccc;
  border-radius: 20px;
  margin-bottom: 50px;
  box-shadow: 0px 20px 20px #ccc;
}
.table-title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.dashboard .trophy {
  font-size: 24px;
  margin-left: 8px;
}
.dashboard .gold.trophy {
  color: gold;
}
.dashboard .silver.trophy {
  color: silver;
}
.dashboard .bronze.trophy {
  color: #cd7f32;
}

.workout-details-page {
  font-weight: bold;
}

.live-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  min-height: 100vh;
  width: 100%;
  background-color: rgb(168 171 174);
  flex-direction: column;
}

.header {
  width: 100%;
}

.workout-title-wrapper {
  position: relative;
  background-color: #262626;
  color: white;
  font-size: 16;
  font-family: Archivo, sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 12px;
}

.current-time {
  position: absolute;
  right: 0;
  margin: 0 12px;
}

.timing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 20px 10px;
}

.round-blocks-wrapper {
  flex: 1 1 0;
  display: flex;
}

.timing-detail {
  font-family: Roboto, sans-serif;
  color: #C3C3C3;
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px;
}

.timing-detail .time-value {
  font-size: 37px;
}

.timing-detail .time-desc {
  font-size: 10px;
}

.round-block {
  flex: 1 1 0;
  margin: 0 10px;
}

.round-block .progress-indicator-outer {
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  background-color: #707070;
}

.round-block.past .progress-indicator-outer {
  background-color: #FE4602;
}

.round-block.current .progress-indicator-outer {
  background-color: #DFDFDF;
}

.round-block .progress-indicator-inner {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #FE4602;
}

.round-block .progress-indicator-mark {
  width: 5px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: -5px;
  right: 0;
}

.round-block .name {
  width: 100%;
  font-size: 20px;
  font-family: Roboto, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #707070;
}

.round-block.past .name {
  color: #707070;
}

.round-block.current .name {
  color: #FE4602;
}

.main-dashboard {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: 40px;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(60,60,60,1) 100%);
  border-top: 1px solid #707070;
}

.main-dashboard h2 {
  color: white;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 30px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 5px;
}

.main-dashboard h3 {
  color: white;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 26px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 5px;
}

.tab-view-wrapper {
  flex: 0 0 28.5%;
  width: 28.5%;
  display: flex;
  flex-direction: column;
}

.tab-view-container {
  background-color: none;

  text-align: center;
  width: 100%;
  min-height: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
}

.leaderboard-wrapper {
  flex: 0 0 43%;
  width: 43%;
  display: flex;
  flex-direction: column;
  font-size: 32px;
}

.leaderboard {
  background-color: black;
  color: white;
  border: 1px solid #707070;
}

.live-row {
  flex: 0 0 28.5%;
  width: 28.5%;
  margin: 0 1rem;
  border-radius: 1rem;
}

.dash-round-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
}

.current-activity {
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  color: #FE4602;
  box-shadow: 0 0 15px 2.5px #FE4602;
  font-size: 3.5vw;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid white;
}

.up-next-wrapper {
  width: 85%;
}

.next-activity {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #111;
  color: white;
  font-size: 2.75vw;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid white;
}

.next-activity:nth-child(2) {
  opacity: 0.6;
}

.activity-timer-wrapper {
  width: 100%;
  text-align: center;
  margin: 30px;
}

.activity-time {
  display: inline-block;
  padding: 2.5px 45px;
  margin: 0 auto 30px;
	position: relative;
	background: #000;
	border: 1px solid #fff;
  font-size: 35px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  color: #FE4602;
}

.activity-time:after, .activity-time:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.activity-time:after {
	border-color: rgba(0, 0, 0, 0);
	border-top-color: #000;
	border-width: 15px;
	margin-left: -15px;
}

.activity-time:before {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #fff;
	border-width: 16px;
	margin-left: -16px;
}

.activity-timer {
  width: 90%;
  margin: 0 auto;
}

.activity-timer .progress-indicator-outer {
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  background-color: #707070;
}

.activity-timer .progress-indicator-inner {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: white;
}

.activity-timer .progress-indicator-mark {
  width: 3px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: -5px;
  right: 0;
}

.leaderboard-container {
  display: flex;
  color: white;
  border: 1px solid white;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
}

.leaderboard-rank {
  flex: 0 0 15%;
}

.leaderboard-name {
  flex: 0 0 35%;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.leaderboard-power {
  flex: 0 0 20%;
}

.leaderboard-pace {
  flex: 0 0 30%;
}

.leaderboard-header {
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #333;
}

.pace-container {
  display: flex;
  align-items: center;
}

.sort-triangle-dsc {
  border-top: 8px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  width: 0;
  height: 0;
  margin-left: 0.25rem;
}

.sort-triangle-asc {
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  width: 0;
  height: 0;
  margin-left: 0.25rem;
}

.tablet-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}