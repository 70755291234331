.eye-icon-password {
  position: absolute;
  top: 35px;
  right: 30px;
  cursor: pointer;
}
.optional-font-weight {
  font-weight: 300;
}

.content-center {
  justify-content: center;
}

.input-password {
  border-radius: 2px;
  padding-left: 16px;
  padding-right: 25px;
  font-size: 14px;
  height: 35px;
  background-color: #fff;
  border-width: 1px;
  width: 100%;
  border: solid 1px#ccc;
}

.name-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.name-group{
  width: 40%;
}

.card-signin input[type="text"] {
  display: block;
  width: 100%;
  height: 44px;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 12px;
  color: #0d1428;
  font-weight: 400;
  line-height: 1.5;
}

input[type="text"]:focus {
  box-shadow: none;
}

.btn-sign,
.btn-sign:hover,
.btn-sign:focus,
.btn-sign:active {
  font-size: 80%;
  border-radius: 0.25rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
  background-color: #f0a344;
  border-color: #f0a344;
  color: #fff;
  margin-top: 1rem;
  box-shadow: none;
  outline: none;
}

.login-body .logo-container {
  height: 40px;
  width: 150px;
  margin: 0 auto;
}

.loading-style{
  text-align: center;
  cursor: pointer;
}