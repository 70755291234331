html {
  font-family: "Poppins", sans-serif;
}
body {
  font-size: 14px;
  color: #0d1428;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}

h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.page-heading {
  text-align: center;
  margin: 30px auto 50px;
  font-size: 40px;
}

.cursor-pointer {
  cursor: pointer;
}
.navbar {
  box-shadow: 0px 0px 8px 0px #ccc;
}
.header-right-menu .btn {
  background-color: #fff;
}

.user-info a {
  font-size: 16px;
  font-weight: 400;
}

.user-info .dropdown-toggle:hover {
  background-color: #f4faff;
}

.user-info .dropdown-toggle:not(:disabled):not(.disabled):active {
  background-color: #f4faff;
  color: #212529;
}

.user-info .dropdown-toggle::after {
  display: none;
}

.user-info .account-user-name {
  padding-left: 6px;
}

/* Card */

.card {
  margin: 20px !important;
  box-shadow: 1px 1px 3px 0px #ccc;
}

.mt-50 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}
.mb-25 {
  margin-bottom: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.floating-btn,
.floating-btn:hover,
.floating-btn:focus,
.floating-btn:active {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  box-shadow: 0px 5px 30px #ff7b23 !important;
  background-color: #ff7b23 !important;
  border-color: #ff7b23 !important;
}
.floating-btn svg {
  height: 36px;
  width: 34px;
}
.slider-container {
  /* overflow: hidden; */
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  padding: 20px;
  background-color: #f5f3f3;
}

.slider-container h3 {
  font-weight: 700;
  font-size: 19px;
  padding: 0;
  padding-left: 10px;
}

/* .user-info {
} */

.header-right-menu .user-info img {
  width: 24px;
  height: auto;
}

.dropdown-container {
  color: #6c757d;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  width: 100%;
  height: auto;
  display: inline-block;
  max-height: 70px;
  overflow-y: auto;
}

.dropdown-container .dropdown-selected-item {
  float: left;
  border: 1px solid;
  font-size: 14px;
  margin: 2px;
  padding: 1px;
}

.dropdown-container .dropdown-selected-item span {
  padding: 0 5px;
  border-left: 1px solid;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
}

.dropdown-custom {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: 1px solid #ccc;
}

.dropdown-custom:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.dropdown-ellipse-selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
}

.create-workout-image {
  max-height: 28vw;
  min-height: 20vw;
  max-width: 100%;
  min-width: 21vw;
}

.edit-workout-image {
  max-height: 28vw;
  min-height: 20vw;
  width: 100%;
}

.create-workout-image-details {
  max-width: 17vw;
}

.left-align-slick > .slick-slider > .slick-list > .slick-track {
  margin-left: 0;
}

.movedown40px {
  margin-top: -7px;
}

.page-errors {
  text-align: center;
  color: red;
  margin-top: -30px;
}

.page-sucess {
  text-align: center;
  color: green;
  margin-top: -30px;
  font-weight: bold;
}

.round-details label:not(.btn) {
  margin-top: 5px;
}

.commaSeparatedSpan:empty {
  display: none;
}

.commaSeparatedSpan:not(:first-child):before {
  content: ", ";
}

.dashboard .table-sm-details th:first-child,
.dashboard table td:first-child {
  width: 50%;
}

.dashboard .table-sm-details td:last-child {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.table-sm-details {
  font-size: 15px;
}

.error-message small {
  font-weight: bold;
}

.workout-container .page-errors {
  font-weight: bold;
}

.form-file-field {
  width: 82%;
}

.poster-image-form {
  margin-left: 8px;
}

.leavet-it-blank {
  margin: 5px 0 0 10px;
}

.fileinputGroup {
  /* margin-left: 10px; */
}

.fileinputGroupSuccess {
  color: green;
}

.eachPadsStyle {
  margin-left: 10px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.dropdown_menu_show {
  overflow-y: auto;
  max-height: 13em;
}

.mma {
  padding-right: 0px;
}

.mma .each-detail {
  margin-left: 3px;
}

.defensive-move {
  padding-left: 0px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 102;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}

.loader-content {
  background-color: white;
  width: 50%;
  height: 50%;
  overflow-y: scroll;
}

/* .modal-close {
  align-self: flex-end;
  padding: 10px;
  cursor: pointer;
}

.modal-info {
  text-align: center;
  padding: 20px;
} */

.error-outline-dropdown {
  border: 2px solid red;
}

.error-outline-input {
  outline: 2px solid red;
}

.input-group .error-outline {
  outline: 2px solid red;
}
