/* .workout {
    background: rgba(0, 0, 0, 0.03);
  } */
.workout .form-group {
  margin: 0;
}
.react-datepicker-wrapper {
  width: 100%;
  z-index: 100;
}
.edit-workout-image{
  object-fit: contain;
  object-position: center center;
}


/* cropper below */
.img-container-interactive{
  /* border-radius: 25px; */
  width: 680px;
  height:480px;
  float: left;
  background-color: rgba(0,0,0,0.2);

}
.img-container-preview{
  width: 200px;
  height:200px;
  float: left;
  margin-top:10px;
  /* margin-left:10px; */
  background-color: rgba(71, 5, 5, 0.2);
  overflow: hidden;

}
.poster-wrapper{
  background-color: rgba(0, 0, 0, 0);
}
.text-field-wrapper{
  position: relative;
}
.medium-text-box{
  resize: none;
  border-color:gray;
  width:100%;
  height:50px;
}
.text-limit{
  position:absolute;
  bottom:0;
  right:0;
  margin-bottom: 7px;
  margin-right: 5px;

  color:gray;
}
.popup{
  position:fixed;
  top:0;
  left: 0;
  width:100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  z-index: 1000;

  display:flex;
  justify-content: center;
  align-items:center;
}
img {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
.popup-inner{
position:relative;
display:flex;
align-items:center;
flex-direction: column;
padding:32px;
width:100%;
max-width: 840px;
max-height: 750px;
border-radius: 25px;
background-color: #fff;
}

#file-upload{
  display: none;
}

.popup-inner .close-btn{
  position:absolute;
  top:16px;
  right:16px;
}
.photo-tool-bar{
  margin-top: 25px;
  display:flex;
  justify-content: space-around;
  width: 35%;
  border-radius: 10px;
  background-color: rgb(12, 1, 1);
  overflow: hidden;

}
.photo-tool-icons{
  color: white;
  width: 20px;
  flex:1;
  margin:0;
  padding: 10px 0;
  text-align: center;
  border-right: 1px solid black;


}
#blue-icons{
  background-color: #00C5d2;
}
#green-icons{
  background-color: #4FD965;
  border: 0;

}
#red-icons{
  background-color: #de0000;

}
/* cropper above */
.radio-buttons .btn {
  background-color: #fff;
  border-color: #ccc;
  color: #000;
  outline: none;
  box-shadow: none;
  padding: 4px 30px;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 14px;
}
.radio-buttons .btn:focus {
  outline: none;
}
.radio-buttons .btn.active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #00c5d2;
  color: #fff;
  border-color: #00c5d2;
}
.each-detail {
  margin-bottom: 20px;
}
.round-box {
  margin: 20px auto;
  border: solid 1px #ccc;
  background: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 10px 10px #ccc;
  padding: 10px 40px;
}
.round-details-holder {
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
  padding: 15px 5px;
}
.mr-40 {
  margin-right: 40px;
}
.header-top {
  font-weight: bold;
  font-size: 16px;
}
.round-details label:not(.btn) {
  margin-top: 20px;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.mt-50 {
  margin-top: 50px;
}
input[type="text"],
input[type="number"] {
  border-radius: 2px;
  padding-left: 16px;
  padding-right: 25px;
  font-size: 14px;
  height: 35px;
  background-color: #fff;
  border-width: 1px;
  width: 100%;
  border: solid 1px#ccc;
}
.workout label:not(.btn) {
  display: block;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
}

.btn.add-round-btn,
.btn.add-round-btn:hover {
  border: solid 1px #00c5d2;
  margin-top: 10px;
  padding: 5px 20px;
  font-size: 12px;
  cursor: pointer;
  background: #00c5d2;
  color: #fff;
}
.workout .create,
.workout .create:hover,
.workout .create:focus {
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  padding: 6px 40px;
  transition: all 0.3s;
  background-color: #de0000;
  border-color: #de0000;
  color: #fff;
  margin: 30px 0 100px;
  letter-spacing: 0.1em;
  padding: 12px 50px;
  border: 2px solid #de0000;
  font-weight: 500;
  font-size: 16px;
  border-radius: 60px 60px 60px 60px;
  text-transform: uppercase;
}

.workout .create:hover {
  color: #de0000;
  background: #fff;
}

.round-box .delete-btn {
  background-color: #de0000;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.workout .draft,
.workout .draft:hover,
.workout .draft:focus {
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  padding: 6px 40px;
  transition: all 0.3s;
  background-color: #fff;
  border-color: #fff;
  color: #de0000;
  margin: 30px 0 100px;
  letter-spacing: 0.1em;
  padding: 12px 50px;
  border: 2px solid #de0000;
  font-weight: 500;
  font-size: 16px;
  border-radius: 60px 60px 60px 60px;
  text-transform: uppercase;
}

.workout .draft:hover {
  color: #fff;
  background: #de0000;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #00c5d2;
  background-color: #00c5d2;
}
.dropdown-menu {
  width: 100%;
}
.btn.dropdown-selector {
  width: 100%;
  height: 35px;
  border-radius: 2px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #110f23 !important;
  border: 1px solid#ccc;
  margin: 0;
  padding: 7px;
  text-align: left;
}
.dropdown-with-arrow .fil-drop {
  padding-right: 20px;
  min-height: 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: none;
  line-height: 20px;
  font-size: 14px;
}
.dropdown-with-arrow .fil-drop:hover,
.dropdown-with-arrow .fil-drop:focus {
  background: #fff;
}
.dropdown-menu.show,
.dropdown-menu.open {
  display: block;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 2.5px 8px 0 #ccc;
  padding: 0;
}
.dropdown-item {
  font-size: 14px;
}
.dropdown-item.align-drop {
  display: flex;
  align-items: center;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #00c5d2;
}
.dropdown-item:hover {
  cursor: default;
  background-color: rgba(0, 197, 210, 0.4);
}
.dropdown-menu.show .dropdown-item,
.dropdown-menu.open .dropdown-item {
  color: #000;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  min-height: 40px;
  line-height: normal;
  padding: 5px 15px;
}
.dropdown-menu.show .dropdown-item:last-child,
.dropdown-menu.open .dropdown-item:last-child {
  border-bottom: 0px solid #ccc;
}
.dropdown-menu.show .dropdown-item:hover,
.dropdown-menu.open .dropdown-item:hover {
  color: #000;
  font-weight: 500;
  background-color: #ccc;
}
.dropdown-item:focus,
.dropdown-item:active {
  color: #212529;
}
.dropdown .dropdown-toggle:after {
  border-top: 0.45em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  color: #99a3ba;
  position: absolute;
  right: 15px;
  top: 15px;
}

.file-field {
  /* width: 228px; */
}

.workout-container .logo {
  margin-left: 0;
}

.custom-file-label {
  border-color: #ccc;
}
.custom-file-label::after {
  color: #fff;
  background-color: #00c5d2;
}

/* 
  .dropdown .checkbox {
    opacity: 0;
    transition: opacity 0.2s;
  } */

.dropdown.open .dropdown-list {
  display: block;
}
.dropdown.open .checkbox {
  transition: 2s opacity 2s;
  opacity: 1;
}
.dropdown.open .dropdown-label:before {
  transform: translateY(-50%) rotate(-180deg);
}
.input-group.date {
  background-color: #fff;
}
.input-group.date input {
  background-color: transparent;
}
.input-group.date .fa {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.alert-for-no-records {
  padding: 20px;
  color: #000000;
  text-align: center;
  font-size: 15px;
  letter-spacing: 3px;
}

.activity {
  padding-left: 2rem;
}

.remove-activity {
  padding: 20px 0;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}

.react-date-width {
  width: 100%;
  background-color: white;
}

.delete-button-position-edit {
  position: absolute;
  top: 35px;
  right: 0;
}
