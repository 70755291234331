.imageStyle {
  max-height: 28vw;
  min-height: 28vw;
  max-width: 23vw;
  min-width: 21vw;
  margin: 0px auto;
  object-fit: cover;
}

.cardBottomText {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 10px;
  text-align: center;
}
.cardBelowBottom {
  font-size: 15px;
}

.card-top-badge {
  font-size: 12px;
  padding: 8px 15px;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 0px;
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  letter-spacing: 2px;
}
.left {
  left: 0px;
}
.right {
  right: 0px;
}

.card-delete {
  position: absolute;
  bottom: 0;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  cursor: pointer;
  background-color: #101010f2;
  z-index: 99;
  opacity: 0;
  color: rgb(214, 212, 212);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  transition: all 0.4s;
}
.card-delete:hover {
  color: #fff;
}
.card-body:hover .card-delete {
  opacity: 1;
  transition: all 0.4s;
}

.box-shadow-hover:hover {
  box-shadow: 0px 6px 20px #0505056e;
}
