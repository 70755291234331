.login-body form {
  height: 100%;
  width: 100%;
}

.login-body {
  background: url("https://nexersys.com/wp-content/uploads/2019/10/Commercial_Product_Image@x2-%E2%80%93-2-1.png")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}
.login-body:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  opacity: 0.5;
}
.login-body .logo {
  height: 40px;
  width: 150px;
  margin: 30px auto 30px;
}

.login-body .card-signin {
  border-radius: 0.5rem;
  border: 0;
  padding: 3rem;
  background: #3a3a3a;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  width: 450px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
.login-body label {
  display: block;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 5px;
}
.login-body .card-signin .btn,
.login-body .card-signin .btn:hover,
.login-body .card-signin .btn:focus,
.login-body .card-signin .btn:active {
  font-size: 80%;
  border-radius: 0.25rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
  background-color: #ff7b23;
  border-color: #ff7b23;
  color: #fff;
  margin-top: 50px;
  box-shadow: none;
  outline: none;
}

.login-body .card-signin .reg-btn,
.login-body .card-signin .reg-btn:hover,
.login-body .card-signin .reg-btn:focus,
.login-body .card-signin .reg-btn:active {
  font-size: 80%;
  border-radius: 0.25rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  margin-top: 25px;
  box-shadow: none;
  outline: none;
}

.login-body .btn:disabled {
  color: #fff;
  opacity: 0.5;
}

.login-body .form-group {
  margin: 0;
}

.login-body input {
  display: block;
  width: 100%;
  height: 44px;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 12px;
  color: #0d1428;
  font-weight: 400;
  line-height: 1.5;
}

.login-body input:focus {
  box-shadow: none;
}

.error-message {
  color: #f85353;
  display: inline-block;
}

.api-error {
  text-align: center;
  font-size: 13px;
}

@media (max-width: 500px) {
  .login-body .card-signin{
    width: 100%;
  }
}