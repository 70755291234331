.content-center {
  justify-content: center;
}

.overlay-container{
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #0d142882;
  cursor: progress;
}