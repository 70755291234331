.slick-track::-webkit-scrollbar {
    display: none !important;
}

.slick-list .slick-track .card {
    margin: 0 10px;
}

.slick-prev:before, .slick-next:before{
    color: #000;
}

.slick-list .slick-track .card-body {
    padding: 0;
}
.slick-prev {
    left: -7px;
}
.slick-next {
    right: -6px;
}

.slick-arrow {
    z-index: 1000
}